<template>
  <moe-page title="提醒事项管理">
    <!-- 筛选信息 -->
    <moe-inquire @search="matterSearch">
      <el-form-item label="提醒事项名称">
        <el-input v-model.trim="matterParams.name" placeholder="输入提醒事项名称" maxlength="50" clearable />
      </el-form-item>
    </moe-inquire>

    <!-- 提醒事项信息 -->
    <moe-table ref="matterTable" url="/remind/pageList" :params="matterParams" row-key="id" :number-show="false">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="danger" icon="el-icon-delete" @click="batchDelete">批量删除</el-button>
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$router.push('/pet-matter/add')">事项添加</el-button>
      </template>
      <el-table-column type="selection" reserve-selection label="ID" width="60" fixed="left" />
      <el-table-column prop="name" label="提醒事项名称" min-width="150" />
      <el-table-column prop="img" label="图片" min-width="100">
        <template slot-scope="{ row }">
          <moe-image :src="row.img" width="70px" height="70px" />
        </template>
      </el-table-column>
      <el-table-column prop="state" label="状态" min-width="90">
        <template slot-scope="{ row }">
          <el-tag type="success" v-if="row.state == -1" @click="matterStatusChange(row.id, 0)"><i class="el-icon-unlock" />启用</el-tag>
          <el-tag type="danger" v-else-if="row.state == 0" @click="matterStatusChange(row.id, -1)"><i class="el-icon-lock" />禁用</el-tag>
          <el-tag type="info" v-else>无效</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" min-width="210" />
      <el-table-column label="操作" width="210" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="warning" size="mini" icon="el-icon-edit" @click="$router.push(`/pet-matter/amend?id=${row.id}`)">修改</el-button>
          <el-button type="success" size="mini" icon="el-icon-view" @click="$router.push(`/pet-matter/detail?id=${row.id}`)">详情</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'matterList',
  data() {
    //提醒事项筛选信息
    let matterParams = {
      pageNum: 1, //当前页
      pageSize: 10, //每页条数
      name: '', //事项名称
    };

    return {
      matterParams, //提醒事项筛选信息
      weightRange: [], //体重范围
    };
  },
  methods: {
    /**
     * 批量删除
     **/
    batchDelete() {
      //获取已选数据ID
      let ids = this.$refs.matterTable.getSelectId();
      if (ids.length === 0) {
        this.$moe_msg.warning('请选择数据 !');
        return;
      }

      this.$moe_layer.confirm('是否要删除选中的提醒事项信息 ?', () => {
        // 调用删除提醒事项API
        this.$moe_api.PET_API.petMatterDelete({ ids: ids.join(',') }).then(
          (data) => {
            if (data.code == 200) {
              this.$moe_msg.success('删除成功');

              //刷新提醒事项表格
              this.$refs.matterTable.clearLoadData();
            } else {
              this.$moe_msg.error(data.message);
            }
          }
        );
      });
    },

    /**
     * 修改事项状态
     **/
    matterStatusChange(id, state) {
      // 调用修改事项状态API
      this.$moe_api.PET_API.petMatterStatus({
        id,
        state,
      }).then((data) => {
        if (data.code == 200) {
          this.$moe_msg.success(state === 0 ? '启用成功' : '禁用成功');

          //刷新账号表格
          this.$refs.matterTable.loadingData();
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },

    /**
     * 提醒事项搜索
     **/
    matterSearch(isSearch) {
      if (!isSearch) {
        this.matterParams = {
          pageNum: 1, //当前页
          pageSize: 10, //每页条数
          name: '', //事项名称
        };
      }

      //刷新提醒事项表格
      this.$refs['matterTable'].clearLoadData();
    },
  },
};
</script>
